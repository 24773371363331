import React from 'react';
import "./Partner.css"

const Partner4 = () => {
    return(
        <div className="container-fluid text-center more">
        <div className="card">
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAElBMVEUAAADa18308+/p6enp5+H+/v8MLkg4AAABFElEQVR4nO3PCQ3DMAAAsTQPf8prNRQX2Qw8ntuNZ8+b7Xc4z7rXmd9wjXstwzzDPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+w77/8Kx7nW+45832O7zdD0WeFNEoVrT6AAAAAElFTkSuQmCC" className="card-img-top" alt="..."/>
        <div className="card-body">
        <span class="badge bg-info text-dark">Mr. Henry Faraji Chipinde</span>
            <p className="card-text">Faraji holds a Bachelor of Laws degree from University of Nairobi and a postgraduate Diploma from the Kenya School of Law. As a Partner at TripleMf & Associates, Faraji is part of a dedicated team whose aim is to provide efficient, quality and professional legal services. He is involved in Commercial Litigation, Civil Litigation and Criminal Litigation with a bias towards Constitutional Law, Company Law/Corporate, Employment matters and Alternative Dispute Resolution.</p>
        
        </div>
    </div>
    </div>
    )
}
export default Partner4;