import React from 'react';

const Service4 = () => {
    return (
        <div className="container-fluid services">
            <div className="container service1">
                <div className="card">
                <h5 className="card-title"><i className="fa fa-legal"><h6>Legal Advisory and Consultancy </h6></i></h5>
                <p className="lead">The Legal Advisory and Consultancy Department provides legal advisory and consultancies services to various (i) governments; (ii) regional and international organisations (iii) professional societies and organisations; (iii) banks, insurance companies and other financial institutions; and (iv) private clients.

</p>
                </div>
            </div>
        </div>
    )
}
export default Service4;