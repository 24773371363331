import React, { Component } from 'react';
import emailjs from "emailjs-com"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
// import "./Contact.css"


class Virtual extends Component {
    constructor(props) {
        super(props);
        this.sendEmail = this.sendEmail.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.state = { startDate: new Date() }
    }
    sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_880vhwk', 'template_lyh5tuc', e.target, 'user_VcbO0ffpwLEY3iKa8rvNn')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          alert("Your information has been submitted, we'll contact you soonest")
          e.target.reset ()
      }
      handleChange(date) {
        this.setState({
          startDate: date
        })
      }
    
    render() { 
        return ( 
            <div className="container-fluid container3">
                <div className="container virtual">

            
            <div className="row padding">
                <div className="col-lg-6 col-sm-12 col-md-6">
                <h4 className="text-center">Virtual Lawyer</h4>
                <p className="lead">TripleMF & Associates is entirely computerized and maintains an extensive and secure database (both electronic and physical) of documents necessary to undertake its diverse portfolios.</p>
                </div>
            
            <div className="col-lg-6">
            
            <form onSubmit={this.sendEmail}>
            <div class="form-floating mb-3">
                <input required type="text" class="form-control" id="floatingInput"  name="fullname" placeholder="name@example.com"/>
                <label for="floatingInput">Full Name</label>
            </div>
            <div class="form-floating mb-3">
                <input required type="email" class="form-control" id="floatingInput" name="email" placeholder="name@example.com"/>
                <label for="floatingInput">Email address</label>
            </div>
            <div class="form-floating mb-3">
                <input required type="text" class="form-control" id="floatingInput" name="number" placeholder="07*********"/>
                <label for="floatingInput">Phone Number</label>
            </div>
            <div class="form-floating mb-3">
                <input required type="text" class="form-control" id="floatingInput" name="subject" placeholder="name@example.com"/>
                <label for="floatingInput">Subject</label>
            </div>
            <select class="form-select form-control" name="priority" aria-label="Default select example">
                <option selected>Priority level</option>
                <option >High</option>
                <option >Low</option>
            </select>
            <select class="form-select" name="contact" aria-label="Default select example">
                <option selected>Preffered mode of contact</option>
                <option >Zoom</option>
                <option >Whatsapp</option>
                <option >Google meet</option>
            </select>
            <div className="form-group">
                <label>Preffered Time</label> <br/>
            <DatePicker

              selected={ this.state.startDate }
              name ="time"
              onChange={ this.handleChange }
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={20}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              className="form-control date"
          />
        </div>
        <div class="form-floating">
                <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea"name="message"></textarea>
                <label for="floatingTextarea">message</label>
            </div>
            <div className="text-center">
            <button type="submit" className="btn btn-warning btn-sm" >Submit</button>
            </div>
            </form>
          
            </div>
            </div>
            </div>
        </div>
         );
    }
}
 
export default Virtual;
