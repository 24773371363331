import React from 'react';
import "./Partner.css"

const Partner2 = () => {
    return(
        <div className="container-fluid text-center more">
        <div className="card">
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAElBMVEUAAADa18308+/p6enp5+H+/v8MLkg4AAABFElEQVR4nO3PCQ3DMAAAsTQPf8prNRQX2Qw8ntuNZ8+b7Xc4z7rXmd9wjXstwzzDPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+w77/8Kx7nW+45832O7zdD0WeFNEoVrT6AAAAAElFTkSuQmCC" className="card-img-top" alt="..."/>
        <div className="card-body">
        <span class="badge bg-info text-dark">Ms. Catherine Muthoni</span>
            <p className="card-text">Ms. Catherine Muthoni Someren holds a Bachelor of Law degree (LL.B Hons) from Moi University, Kenya and Post Graduate Diploma in Law from the Kenya School of Law. Currently, Muthoni is pursuing her LL.M degree with specialization in International Trade and Investments Law from the University of Nairobi. She is an Advocate of the High Court of Kenya and a diligent Researcher.  
Her main areas of practice include Conveyance and Commercial Transactions Law, Banking Law and Financial Regulation, Corporate law, Tax law, intellectual property law, constitutional law, public administrative law and Policy, defamation law, land and environmental law, labour law, and Forensic Legal Audits of state agencies and private companies. 
</p>
        </div>
    </div>
    </div>
    )
}
export default Partner2;