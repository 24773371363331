import React from 'react';
import "./Navbar.css"
import { Link } from 'react-router-dom';
import Logo from "./TripleMf.png"

const Navbar = () => {
    return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="/"><img src = {Logo} className="img-fluid" alt="..."/></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    
                    <li className="nav-item dropdown">
                    
                    <a class="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Home</a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/">
                    <a class="dropdown-item" href="/" >Home</a>
                    </Link></li>
                        <li><Link to="/about">
                    <a class="dropdown-item" href="/">About Us</a>
                    </Link></li>
                        <li><Link to="/services">
                    <a class="dropdown-item" href="/">Our Services</a>
                    </Link></li>
                        <li><Link to="/team">
                    </Link></li>
                    <li><Link to="/clients">
                    <a class="dropdown-item"href="/" >Our Clients</a>
                    </Link></li>
                    </ul>
                    </li>
                    
                    
                    <li className="nav-item dropdown">
                    
                    <a class="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Our Proffessionals</a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/partner">
                    <a class="dropdown-item" href="/partner" >Our Partners</a>
                    </Link></li>
                        <li><Link to="/">
                    <a class="dropdown-item" href="/">Our Team</a>
                    </Link>
                    </li>
                    </ul>
                    </li>
                    <li className="nav-item">
                    <Link to="/virtual">
                    <a className="nav-link" href="/">Virtual lawyer</a>
                    </Link>
                    </li>
                    
                    
                    <li className="nav-item">
                    <Link to="/">
                    <a className="nav-link" href="/">News and Updates</a>
                    </Link>
                    </li>
                    
                    
                    <li className="nav-item">
                    <Link to="/contact">
                    <a className="nav-link" href="/">Contact us</a>
                    </Link>
                    </li>
                    
                </ul>
                </div>
            </div>
            </nav>
    )
}
export default Navbar;