import React from 'react';
import {BrowserRouter as Router } from 'react-router-dom';
import "./Home.css"
import Carousel from "./carousel"
import About from "./about"
import Services from './services';
import Clients from './clients';

const Home = () => {
    return (
        <div>
          <Router>
              <Carousel/>
              <About/>
              <Services/>
              <Clients/>
          </Router>
        </div>
    )
}
export default Home;