import React from 'react';
import "./Contact.css"



const Contact = () => {
   
    return (
        <div className="container-fluid contact">
            <div className="row padding contact-row">
            <h2>TRIPLEMF & ASSOCIATES</h2>
                <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="container container1" >
                    <h6>Lets's Get in Touch</h6>
                    <h9>You can link with us at any time at any place</h9><br/>
                    <div className="container" style={{paddingTop:"10px"}} >
                    <i className="fa fa-map-marker"> Location</i><p>Couper Court, 2nd Floor, Suite No. 3 <br/>
Upper Hill<br/>
Opposite Citi Bank, Next to The British High Commision<br/>
</p>
                    <i className="fa fa-mobile"> Mobile Phone</i><p>+254 724 769952</p>
                    <i className="fa fa-envelope"> Email</i><p>farajichipinde@triplemflaw.com</p>
                    
                    </div>
                   
                    
                </div>
                
                </div>
                <div className="col-lg-6">
                <div className="container container7">
                   <div className="social-icons">
                    <i className="fa fa-linkedin"></i>
                    <i className="fa fa-whatsapp"></i>
                    <i className="fa fa-twitter"></i>
                    <i className="fa fa-facebook"></i>
                </div>
                </div>
                
                </div>
                
            </div>
            
        </div>
    )
}
export default Contact;