import React from 'react';
import "./News.css"

const News = () => {
    return (
        <div className="container-fluid news-fluid">
            <div className="container news">
                <h3 className="text-center">Our News and Updates</h3>
                <div className="card">
                    <img src="https://images.pexels.com/photos/5668481/pexels-photo-5668481.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" class="card-img-top" alt="..."/>  
                    <div className="card-body">
                        <h5 className="card-title">TripleMF wins a land case</h5>
                        <p className="card-text">Our panel led by Barasa was victorious again in a tough case, representing the government.<br/> The judge Emoji Sifuna gave the government a green light to cotinue with the demolition in Kiwanja as the land belongs to the government.</p>
                    </div>
                </div>
                <div className="card">
                    <img src="https://images.pexels.com/photos/6045233/pexels-photo-6045233.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" class="card-img-top" alt="..."/>  
                    <div className="card-body">
                        <h5 className="card-title">Scheduled Online Summit</h5>
                        <p className="card-text">Don't forget to join our online summit led by Faraji on 18th August 2021 at 11:00 am East African Time.<br/> The main discusiion will be about human rights and lay emphasis on how the BBI affects them.<br/> <a href="https://triplemf.netlify.app/">Click here to join the summit</a></p>
                    </div>
                </div>
               
            </div>
        </div>
    )
}
export default News;