import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import Navbar from "./components/Navbar/Navbar"
import Home from './components/Home/Home';
import Contact from './components/Contact/Contact';
import { BrowserRouter as Router,Route } from 'react-router-dom';
import Partner from './components/Partners/Partner';
import About from './components/Home/about';
import Carousel from './components/Home/carousel';
import Services from './components/Home/services';
// import Team from './components/Home/team';
import News from './components/News/News';
import Footer from "./components/Footer/Footer"
import Partner1 from './components/Partners/partner1';
import Partner2 from './components/Partners/Partner2';
import Partner3 from './components/Partners/Partner3';
import Partner4 from './components/Partners/Partner4';
import Virtual from './components/Virtual/Virtual';
import Clients from './components/Home/clients';
import Service1 from './components/Home/service1';
import Service2 from './components/Home/service2';
import Service3 from './components/Home/service3';
import Service4 from './components/Home/service4';
const App = () => {
  return (
    <div>
      <Router>
        <Route component= {Navbar}/>
        <Route path="/" exact component={Home}/>
        <Route path="/contact"  component={Contact}/>
        <Route path="/partner"  component={Partner}/>
        <Route path="/virtual"  component={Virtual}/>
        <Route path="/news"  component={News}/>
        <Route path="/home" component={Carousel}/>
        <Route path="/about" component={About}/>
        <Route path="/services" component={Services}/>
        {/* <Route path="/" component={Team}/> */}
        <Route path="/clients" component={Clients}/>
        <Route path="/partner1" component={Partner1}/>
        <Route path="/partner2" component={Partner2}/>
        <Route path="/partner3" component={Partner3}/>
        <Route path="/partner4" component={Partner4}/>
        <Route path="/service1" component={Service1}/>
        <Route path="/service2" component={Service2}/>
        <Route path="/service3" component={Service3}/>
        <Route path="/service4" component={Service4}/>
        <Footer/>
      </Router>
      
    </div>
  )
}
export default App;