import React from 'react';
import person1 from "./person_1.jpg"
import person2 from "./person_2.jpg"
import person3 from "./person_3.jpg"
import person5 from "./person_5.jpg"
import person6 from "./person_6.jpg"
import person7 from "./person_7.jpg"
import person8 from "./person_8.jpg"
import person9 from "./person_9.jpg"
import person10 from "./person_10.jpg"

const Clients = () => {
    return(
        <div className="container-fluid clients">
            <h3 className="text-center">Our Clients</h3>
            <div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-bs-interval="2000" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
        <div className="row padding">
            <div className="col-lg-4 col-sm-4 col-md-4">
            <div class="card" >
            <img src={person1} class="card-img-top" alt="..."/>
                <div class="card-body">
                    <h7 class="card-title">County Assembly of Siaya</h7>
                    
                </div>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="card" >
            <img src={person2} class="card-img-top" alt="..."/>
                <div class="card-body">
                    <h7 class="card-title">Nairobi City County</h7>
                    
                </div>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="card" >
            <img src={person3} class="card-img-top" alt="..."/>
                <div class="card-body">
                    <h7 class="card-title">National Environmental Management Authority</h7>
                    
                </div>
            </div>
            </div>
        </div>
        
    </div>
    <div class="carousel-item">
    <div className="row padding">
            <div className="col-lg-4 col-sm-4 col-md-4">
            <div class="card" >
            <img src={person10} class="card-img-top" alt="..."/>
                <div class="card-body">
                    <h7 class="card-title">Sacco Societies Regulatory Authority</h7>
                    
                </div>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="card" >
            <img src={person5} class="card-img-top" alt="..."/>
                <div class="card-body">
                    <h7 class="card-title">Law Society Of Kenya</h7>
                    
                </div>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="card" >
            <img src={person6} class="card-img-top" alt="..."/>
                <div class="card-body">
                    <h7 class="card-title">Hatari Security Services</h7>
                    
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="carousel-item">
    <div className="row padding">
            <div className="col-lg-4 col-sm-4 col-md-4">
            <div class="card" >
            <img src={person7} class="card-img-top" alt="..."/>
                <div class="card-body">
                    <h7 class="card-title">Uniabord Education Sevices</h7>
                    
                </div>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="card" >
            <img src={person8} class="card-img-top" alt="..."/>
                <div class="card-body">
                    <h7 class="card-title">Informatics Marketing and Research Limited</h7>
                </div>
            </div>
            </div>
            <div className="col-lg-4">
            <div class="card" >
            <img src={person9} class="card-img-top" alt="..."/>
                <div class="card-body">
                    <h7 class="card-title">Kibo</h7>
                </div>
            </div>
            </div>
        </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
        </div>
    )
}
export default Clients;