import React from 'react';
import "./Home.css"

const Services = () => {
    return (
        <div className="container-fluid about">
        <h3 className="text-center">Our Practice Areas</h3>
        <p className="lead">Our practice areas include: Commercial and banking law; Environment law; Conveyancing; Insurance law; Company law; Trademarks and copy rights law; Labour and Employment law; Policy development; Legal Advisory and Consultancy; Constitutional and Judicial Review; and Land and Rates Law.

To effectively cater for our clientele, TripleMf & Associates is organized into the Legal Advisory and Consultancy Department; Litigation and Dispute Resolution Department; Commercial Department; and Conveyancing and Real Estate Law Department.</p>
        <div className="row padding">
        
            <div className="col-lg-3 col-sm-12 col-md-6">
            <div className="card">
            <div className="card-body text-center">
                    <h5 className="card-title">  <i className="fa fa-envelope"><h6>Litigation and Arbitration </h6></i></h5>
                    
                    <a  href="/service1" className="btn btn-info btm-sm">Read More</a>
                    
                </div>
            </div>
            </div>
            <div className="col-lg-3">
            <div className="card">
                <div className="card-body text-center">
                    <h5 className="card-title"><i className="fa fa-user-circle-o"><h6>Commercial Transactions  </h6></i></h5>
                
                    <a href="/service2" className="btn btn-info btm-sm">Read More</a>
                </div>
            </div>
            </div> 
            <div className="col-lg-3">
            <div className="card">
                <div className="card-body text-center">
                    <h5 className="card-title"><i className="fa fa-building"><h6>Real Estate Transactions</h6></i></h5>
                
                    <a href="/service3" className="btn btn-info btm-sm">Read More</a>
                </div>
            </div>
            </div> 
            <div className="col-lg-3">
            <div className="card">
                <div className="card-body text-center">
                    <h5 className="card-title"><i className="fa fa-legal"><h6>Legal Advisory and Consultancy</h6></i></h5>
                
                    <a href="/service4" className="btn btn-info btm-sm">Read More</a>
                </div>
            </div>
            </div> 
        </div>
       
       
    </div>
    )
}
export default Services;