import React from "react";
import "./Footer.css";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div>
            <footer className="container-fluid page-footer">
        <div className="row padding">
          <div className="col-lg-4 col-sm-12 col-md-6">
            <div className="quick-links">
              <h6>Quick Links</h6>
              <p><Link to ='/about'><a href="/about" >About us</a>
              </Link></p>
              <p><Link to ='/services'><a href="/services">Our services</a></Link></p>
              <p><Link to ='/'><a href="/">Our team</a></Link></p>
            </div>
          </div>
          <div className="col-lg-4">
          <div className="our-partners">
              <h6>Our Social Media</h6>
              <div className="social-icons">
                   <p> <i className="fa fa-linkedin"></i> <a href="https://www.linkedin.com/in/triplemf-law-4316a821b/">  Linkedin</a></p>
                   <p> <i className="fa fa-whatsapp"></i> <a href="https://wa.me/254724769952">  Whatsapp </a></p>
                   <p> <i className="fa fa-youtube"></i> <a href="https://www.youtube.com/channel/UCO3zXRPFWdI05PLViBV7DjA/">  Youtube</a> </p>
                   <p><i className="fa fa-twitter"></i> <a href="https://twitter.com/TripleMflaw">  Twitter </a></p>
                   <p><i className="fa fa-facebook"></i> <a href="https://en-gb.facebook.com/The-bar-254-by-TripleMf-225777716087639/?ti=as">  Facebook </a> </p>
                   <p><i className="fa fa-instagram"></i> <a href="https://www.instagram.com/thebar254/">  Instagram</a></p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="contact-us">
              <h6>Contact us</h6>
              <p>Mobile: +254 724 769952</p>
              <p>Email:  farajichipinde@triplemflaw.com</p>
              <p>Couper Court, 2nd Floor, Suite No. 3 <br/>
Upper Hill<br/>
Opposite Citi Bank, Next to The British High Commision<br/>
</p>
            </div>
          </div>
          <div className="text-center copyright">
              <h5>&copy; TripleMF & Associates 2021</h5>
          </div>
        </div>
      </footer>
        </div>
    )
}
export default Footer;