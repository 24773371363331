import React from 'react';
import "./Home.css"
import Bannerimg1 from "./image3.jpg"
import Bannerimg2 from "./image6.jpg"
import Bannerimg3 from "./image5.jpg"

const Carousel = () => {
    return (
        <div id="carouselExampleCaptions" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
          <img src={Bannerimg1} className="img-fluid" alt="..."/>
          <div className="carousel-caption d-none d-md-block">
              <h3 style={{color:"orange",paddingBottom:"10px"}}>TRIPLEMF &</h3>
              <h3 style={{color:"orange",paddingBottom:"10px"}}>ASSOCIATES</h3>
              <h3>ATTORNEYS FIGHTING FOR YOUR FREEDOM</h3>
              <p >TripleMF & Associates, is the best law firm that you can look for. <br/>All attorneys here are licensed in Kenya. <br/>This firm is highly regarded and has a strong reputation for quality legal services and provide a widest range of legal services</p>
              
              <a href="/virtual" className="btn  btn-lg btn-outline">Make an appointment</a>
          </div>
          </div>
          <div className="carousel-item">
          <img src={Bannerimg2} className="img-fluid" alt="..."/>
            <div className="carousel-caption d-none d-md-block">
            <h3 style={{color:"orange",paddingBottom:"10px"}}>TRIPLEMF &</h3>
              <h3 style={{color:"orange",paddingBottom:"10px"}}>ASSOCIATES</h3>
              <h3>WE ARE SPECIALIST IN LEGAL CRIMINAL & BUSINESS LAW</h3>
              <p >The greatness of any nation lies in its fidelity to the constitution and adherence to the rule of law and above all respect to God
by Former Chief Justice David Maraga
.</p>
            </div>
          </div>
          <div className="carousel-item">
          <img src={Bannerimg3} className="img-fluid" alt="..."/>
            <div className="carousel-caption d-none d-md-block">
            <h3 style={{color:"orange",paddingBottom:"10px"}}>TRIPLEMF &</h3>
              <h3 style={{color:"orange",paddingBottom:"10px"}}>ASSOCIATES</h3>
              <h3 style={{color:"black"}}>ATTORNEYS FIGHTING FOR YOUR FREEDOM</h3>
              <p style={{color:"black",fontWeight:"500"}} >Success is failure turned inside out. If you want to graduate from a “Johny nobody” to a big “Kahuna” you must obey the divine Order to subdue the earth and have dominion over it; otherwise the earth will subdue you and have dominion over you
by PLO Lumumba
.</p>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    )
}
export default Carousel;