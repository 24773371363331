import React from 'react';
import "./Home.css"

const About = () => {
    return (
        
        <div className="container-fluid practice">
        <div className="row padding">
            <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="container container-practice1" style={{borderWidth:"5px"}}>
                <div className="card">
                <h4>Mission Statement</h4>
                <p className="lead">To be one of the premier commercial and conveyancing Law Firms in Nairobi, offering wholistic and practicable solutions to our clients’ challenges with personalized and quality legal services to our clients at an affordable cost.</p>
                
                
            </div>
            </div>
            </div>
            <div className="col-lg-6"  style={{paddingTop:"1x"}}>
            <div className="container container-practice2">
            <div className="card">
            <h4>Our Organisation</h4>
                <p className="lead">Maura, Muthoni, Mikhala, Faraji & Associates is one of Kenya’s new generation law firms. The firm is recognised for its pre-eminence in providing specialised legal and regulatory advisory services, high level dispute resolution and handling complex and big value transactions.</p>
                <p className="lead">TripleMf & Associates is a mid-size law firm comprising four partners, two associates, three specialist consultants and a dedicated team of support staff offering expert legal advice to businesses, corporate entities, banks, insurance and financial institutions, governments and private clients. Our team members have varied educational and professional backgrounds with exposure to Kenyan and other legal systems, helping the Firm to find innovative solutions to our client’s challenges.</p>
                <p className="lead">We place great premium on continual learning to ensure that TripleMf & Associates remains dynamic, competitive and well-informed.  This has given the Firm a good blend of lawyers who are authorities in diverse fields, including legal practice and academia.</p>
            </div>
            </div>
        </div>
        </div>
    </div>
    )
}

export default About;