import React from 'react';

const Service2 = () => {
    return (
        <div className="container-fluid services">
            <div className="container service1">
                <div className="card">
                <h5 className="card-title"><i className="fa fa-user-circle-o"><h6>Commercial Transactions </h6></i></h5>
                <p className="lead">The Commercial Transactions Department provides a wide range of commercial advisory and transactional services to clients, including undertaking due diligence, negotiating restructurings, advising on public listing, rights issues, asset sales and financing through single lender or syndicate loans.
We have developed particular expertise in Capital Markets, Energy and Infrastructure, Information and Communication Technology and Financial Services. As a result of such broad expertise, we are able to advise on a wide range of issues, generating solutions to complex matters.

</p>
                </div>
            </div>
        </div>
    )
}
export default Service2;