import React from 'react';

const Service1 = () => {
    return (
        <div className="container-fluid services">
            <div className="container service1">
                <div className="card">
                <h5 className="card-title">  <i className="fa fa-envelope"><h6>Litigation and Arbitration </h6></i></h5>
                <p className="lead">The firm commands respect within the Republic of Kenya for its meticulous handling of cases.  The firm prosecutes and defends matters in all Courts in the land but is majorly engaged with matters in the High Court. Moreover, the firm handles matters in the Lower Court; Rent Restriction Tribunal and Business Premises Tribunal; Land Acquisition Compensation Committee and National Environmental Tribunal.  
The Litigation and Arbitration Department is involved in day-to-day litigation before domestic, regional and international courts and tribunals.  The Department boasts a hard-to-rival success rate, making us the Firm of choice in respect of complex litigation and arbitration matters.
</p>
                </div>
            </div>
        </div>
    )
}
export default Service1;