import React from 'react';

const Service3 = () => {
    return (
        <div className="container-fluid services">
            <div className="container service1">
                <div className="card">
                <h5 className="card-title"><i className="fa fa-building"><h6>Real Estate Transactions Department</h6></i></h5>
                <p className="lead">The Real Estate Transactions Department deals with Land and Conveyancing transactions, including (i) transfers (ii) conveyances; (iii) leases; (iv) subdivisions; (v) mortgages and (vi) charges.

</p>
                </div>
            </div>
        </div>
    )
}
export default Service3;