import React from 'react';
import "./Partner.css"

const Partner1 = () => {
    return(
        <div className="container-fluid text-center more">
        <div className="card">
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAElBMVEUAAADa18308+/p6enp5+H+/v8MLkg4AAABFElEQVR4nO3PCQ3DMAAAsTQPf8prNRQX2Qw8ntuNZ8+b7Xc4z7rXmd9wjXstwzzDPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+w77/8Kx7nW+45832O7zdD0WeFNEoVrT6AAAAAElFTkSuQmCC" className="card-img-top" alt="..."/>
        <div className="card-body">
        <span class="badge bg-info text-dark">Mr. Felix Maura</span>
            <p className="card-text">Maura holds a Bachelor of Laws from Moi University (LL.B) and a Diploma from Kenya School of Law with elaborate experience in litigation and a bias towards Industrial and labour relations, conveyancing, Constitutional law and Judicial Review, Land Law, Environmental law and Commercial Law. 
Currently, Maura is pursuing an MBA degree with specialization in Economics from Mount Kenya University.
</p>
        </div>
    </div>
    </div>
    )
}
export default Partner1;