import React from 'react';
import "./Partner.css"

const Partner3 = () => {
    return(
        <div className="container-fluid text-center more">
        <div className="card">
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAElBMVEUAAADa18308+/p6enp5+H+/v8MLkg4AAABFElEQVR4nO3PCQ3DMAAAsTQPf8prNRQX2Qw8ntuNZ8+b7Xc4z7rXmd9wjXstwzzDPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+wz7DPsM+w77/8Kx7nW+45832O7zdD0WeFNEoVrT6AAAAAElFTkSuQmCC" className="card-img-top" alt="..."/>
        <div className="card-body">
        <span class="badge bg-info text-dark">Ms. Mikhala Cellestine Barasa</span>
            <p className="card-text">Ms. Mikhala Barasa Cellestine holds a Law Degree from Moi University. She attended the Kenya School of Law and was admitted to the bar in 2014. She is an ardent legal researcher, has excellent drafting skills. Her main areas of expertise are civil litigation, commercial law and legal audits.  </p>
            
        </div>
    </div>
    </div>
    )
}
export default Partner3;